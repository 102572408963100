const MenuRoutes = [
  {
    key: 'administracion',
    name: 'Administración',
    children: [
      {
        name: 'Reservar',
        key: '/reservar',
        path: '/reservar',
      },
      {
        name: 'Reservaciones',
        key: '/reservas',
        path: '/reservas',
      },
      {
        name: 'Diario de venta',
        key: '/diario-ventas',
        children: [
          {
            name: 'Diario de venta',
            key: '/reportes/excursiones',
            path: '/reportes/excursiones',
          },
          {
            name: 'Diario de venta - Consecutivo',
            key: '/reportes/contabilidad',
            path: '/reportes/contabilidad',
          },
          {
            name: 'Diario de venta - Cotización',
            key: '/reportes/cotizacion',
            path: '/reportes/cotizacion',
          },
        ]
      },
      {
        name: 'Reporte Comisiones',
        key: '/reportes-comisiones',
        children: [
          {
            name: 'Reportes Comisiones Reps',
            key: '/reportes/comisiones',
            path: '/reportes/comisiones',
          },
          {
            name: 'Comisiones Detalladas por Rep',
            key: '/reportes/comisiones/details',
            path: '/reportes/comisiones/details',
          },
          {
            name: 'Reporte Comisiones Gerentes',
            key: '/reporte/gerentes',
            path: '/reporte/gerentes',
          },
          {
            name: 'Comisiones por canal',
            key: '/report/commissions-by-channel',
            path: '/report/commissions-by-channel',
          },
          {
            name: 'Comisiones Pagadas',
            key: '/comisiones/pagadas',
            path: '/comisiones/pagadas',
          },
        ]
      },
      {
        name: 'Reporte Producción',
        key: '/reporte-produccion',
        children: [
          {
            name: 'Reporte Producción',
            key: '/reporte/produccion',
            path: '/reportes/produccion',
          },
          {
            name: 'Reporte Producción Anual',
            key: '/reporte/produccionanual',
            path: '/reportes/produccionanual',
          },
          {
            name: 'Reporte Producción Anual x Proveedor',
            key: '/reportes/produccionanual/proveedor',
            path: '/reportes/produccionanual/proveedor',
          },
          {
            name: 'Produccion por Proveedor',
            key: '/produccion/proveedor',
            path: '/produccion/proveedor',
          },
          {
            name: 'Reporte de ventas/produccion anual',
            key: '/reporte/ventas-produccion-anual',
            path: '/reporte/ventas-produccion-anual',
          },
          {
            name: 'Reporte conversion campañas (WEB)',
            key: '/reporte/conversion-camp',
            path: '/reporte/conversion-camp',
          },
          {
            name: 'Reporte Comparativo Anual',
            key: '/reporte/comparativo/anual',
            path: '/reporte/comparativo/anual',
          },
          {
            name: 'Reporte Comparativo Anual x Mes',
            key: '/reporte/comparativo/mensual',
            path: '/reporte/comparativo/mensual',
          },
          {
            name: 'Reporte productos más vendidos',
            key: '/report/most-selled-last-week',
            path: '/report/most-selled-last-week',
          },
          {
            name: 'Reporte Cierre por día',
            key: '/cierre/dia',
            path: '/cierre/dia',
          },
          {
            name: 'Reportes Venta por País',
            key: '/reporte/pais/venta',
            path: '/reporte/pais/venta',
          },
        ]
      },
      {
        name: 'Reportes Formas de Pago',
        key: '/formas-de-pago',
        children: [
          {
            name: 'Ventas Dolares Efectivo',
            key: '/reporte/ventas_dolares',
            path: '/reporte/ventas_dolares',
          },
          {
            name: 'Reporte Cash-Certificado',
            key: '/reporte/cashcertificado',
            path: '/reporte/cashcertificado',
          },
          {
            name: 'Ventas y Liquidación diarias por Vendedor',
            key: '/reporte/liquidacion',
            path: '/reporte/liquidacion',
          },
          {
            name: 'Reporte de forma de cobro liquidación ventas Diaria',
            key: '/reporte/cobro',
            path: '/reporte/cobro',
          },
          {
            name: 'Reporte de cobros',
            key: '/reportes/excursionesTwo',
            path: '/reportes/excursionesTwo',
          },
          {
            name: 'Formas de Pago',
            key: '/reservas/administrar',
            path: '/reservas/administrar',
          },
        ]
      },
      {
        name: 'Operaciones',
        key: '/operaciones',
        children: [
          {
            name: 'Reporte Transportacion',
            key: '/reporte/transporte',
            path: '/reporte/transporte',
          },
          {
            name: 'Costeo de servicios',
            key: '/reporte/transporte/costeo',
            path: '/reporte/transporte/costeo',
          },
          {
            name: 'Lista de llegadas',
            key: '/reporte/transporte/llegada',
            path: '/reporte/transporte/llegada',
          },
          {
            name: 'OPS VCM llegadas-salidas',
            key: '/reporte/transporte/ops',
            path: '/reporte/transporte/ops',
          },
          {
            name: 'Reporte de Traslados',
            key: '/reporte/transporte/traslados',
            path: '/reporte/transporte/traslados',
          },
          {
            name: 'Agrupar Compartidos',
            key: '/agrupar-compartidos',
            path: '/agrupar-compartidos',
          },
          {
            name: 'Reporte Agrupar Compartidos',
            key: '/reporte-agrupar-compartidos',
            path: '/reporte-agrupar-compartidos',
          },
          {
            name: "Reporte Pax Reales",
            key: '/report/real-pax',
            path: '/report/real-pax',
          },
          {
            name: 'Reporte clientes',
            key: '/report/customers-by-range',
            path: '/report/customers-by-range',
          },
        ],
      },
      {
        name: 'Contabilidad',
        key: '/contabilidad',
        children: [
          {
            name: 'Adicionar Factura y Buscar  Factura',
            key: '/adicionar/factura',
            path: '/adicionar/factura',
          },
          {
            name: 'Cúpones Conciliados',
            key: '/cupones/conciliados',
            path: '/cupones/conciliados',
          },
          {
            name: 'Cúpones No Conciliados',
            key: '/cupones/no/conciliados',
            path: '/cupones/no/conciliados',
          },
          {
            name: 'Calculo Costo Excursiones',
            key: '/calculo/costo/excursiones',
            path: '/calculo/costo/excursiones',
          },
          {
            name: 'Reporte de descuentos',
            key: '/reporte/descuentos',
            path: '/reporte/descuentos',
          },
        ]
      },
      {
        name:'Asignaciones',
        key: '/asignacion-locacion',
        children:[
          {
            name: 'Asignar Locación',
            key: '/presupuesto/asignacion',
            path: '/presupuesto/asignacion'
          },
          {
            name: 'Creacion de Periodos',
            key: '/presupuesto/periodos',
            path: '/presupuesto/periodos'
          },
          {
            name: 'Reporte de Asistencia',
            key: '/presupuesto/reporte',
            path: '/presupuesto/reporte'
          },
          {
            name: 'Asignación de Rol Diario De Supervisión De Ventas',
            key: '/asignacion/rol-diario-supervision',
            path: '/asignacion/rol-diario-supervision'
          },
        ],
      },
      {
        name: 'Otros Reportes',
        key: '/otros-reportes',
        children: [
          {
            name: 'Reporte Cupones Cancelados',
            key: '/reporte/cancelacion',
            path: '/reporte/cancelacion',
          },
          {
            name: 'Reporte Incentivos',
            key: '/incentives/report',
            path: '/incentives/report',
          },
          {
            name: 'Reporte OTLC',
            key: '/reporte/otlc',
            path: '/reporte/otlc',
          },
          {
            name: 'Reporte Pickups',
            key: '/reporte/pickups',
            path: '/reporte/pickups',
          },
          {
            name: 'Reporte Agencia Comercial',
            key: '/reporte/agencias/comercial',
            path: '/reporte/agencias/comercial',
          },
          {
            name: 'Reporte Hampton',
            key: '/reporte/hampton',
            path: '/reporte/hampton',
          },
          {
            name: 'Reporte Edicion',
            key: '/reporte/edicion',
            path: '/reporte/edicion',
          },
          {
            name: "Reporte Comparativo",
            key: '/report/comparative-by-dates',
            path: '/report/comparative-by-dates',
          },
        ],
      },
      {
        name: 'Ligas de venta',
        key: '/reps-leagues',
        path: '/reps-leagues',
      },
      {
        name: 'Solicitudes de cancelaciones',
        key: '/solicitudes-de-cancelacion',
        path: '/solicitudes-de-cancelacion',
      },
      {
        name: 'Leds',
        key: '/reservas/leds',
        children: [
          {
            name: 'Asignación',
            key: '/reservas/leds/asignacion',
            path: '/reservas/leds/asignacion',
          }
        ]
      }
    ],
  },
  // TODOS PARA ROLES DE ENCARGADO Y AUXILIAR
  {
    name: 'Comisiones',
    key: '/comisiones',
    children: [
      {
        name: 'Comisiones ',
        key: '/commissions-by-channel',
        path: '/commissions-by-channel',
        children: [
          {
            name: 'Comisiones canales',
            key: '/commissions-by-channel',
            path: '/commissions-by-channel',
          },
        ],
      },
      {
        name: 'Canales ',
        key: '/comisiones/canales',
        path: '/comisiones/canales',
      }
    ],
  },
  {
    key: '/costo-proveedores',
    name: 'Costo Proveedores',
    children: [
      {
        name: 'Costo proveedores',
        key: '/costo/proveedores',
        path: '/costo/proveedores',
      },
      {
        name: 'Calculo costo excursiones x servicio',
        key: '/costo/excursionesxservicio',
        path: '/costo/excursionesxservicio',
      },
      {
        name: 'Calculo costo excursiones x Moneda',
        key: '/costo/excursionesxservicio/currency',
        path: '/costo/excursionesxservicio/currency',
      },
    ],
  },
  {
    key: '/presupuestos',
    name: 'Presupuesto',
    children: [
      {
        name: 'Crear Presupuesto',
        key: '/crear/presupuesto',
        path: '/crear/presupuesto',
      },
      {
        name: 'Ver Presupuestos',
        key: '/presupuesto/ver',
        path: '/presupuesto/ver',
      },
      {
        name: 'Grado de Alcance',
        key: '/reporte/presupuesto/grado-alcance',
        path: '/reporte/presupuesto/grado-alcance',
      },
      {
        name: 'Grado de Alcance x Días',
        key: '/reporte/presupuesto/grado-alcance/dias',
        path: '/reporte/presupuesto/grado-alcance/dias',
      },
      {
        name: 'Reporte Ranqueo',
        key: '/reporte/presupuesto/ranqueo',
        path: '/reporte/presupuesto/ranqueo',
      },
      {
        name: 'Ranqueo x Días',
        key: '/reporte/presupuesto/ranqueo/dias',
        path: '/reporte/presupuesto/ranqueo/dias',
      },
      {
        name: 'Modificar Presupuesto',
        key: '/modificar/locacion/rep',
        path: '/modificar/locacion/rep',
      },
      {
        name: 'Porcentaje de ocupación',
        key: '/occupancy-percentage',
        path: '/occupancy-percentage',
      },
    ],
  },
  {
    name: 'Newsletter',
    key: '/newsletter',
    path: '/newsletter',
  },
  {
    name: 'Catálogos',
    key: '/catalogs',
    path: '/catalogs',
  },
  {
    key: '/comercial',
    name: 'Comercial',
    children: [
      {
        name: 'Ficha técnica',
        key: '/grupos/ficha-tecnica',
        path: '/grupos/ficha-tecnica',
      },
      {
        name: 'Reservar Proforma',
        key: 'proforma',
        path: '/proforma',
      },
      {
        name: 'Reservaciones Proforma',
        key: 'proforma-reservations',
        path: '/proforma-reservations',
      },
      {
        name: 'Reportes',
        key: '/comercial/reportes',
        children: [
          {
            name: 'Reporte comercial',
            key: '/comercial/reportes/CommercialReport',
            path: '/comercial/reportes/CommercialReport',
          },
          {
            name: 'Reporte descuentos servicios comercial',
            key: '/comercial/reportes/descuentos-servicios-comercial',
            path: '/comercial/reportes/descuentos-servicios-comercial',
          },
          {
            name: 'Reporte cobranza',
            key: '/comercial/reportes/reporte_cobranza',
            path: '/comercial/reportes/reporte_cobranza',
          },
          {
            name: 'Reporte tarifario De Excursiones',
            key: '/comercial/reportes/tarifario-excursiones',
            path: '/comercial/reportes/tarifario-excursiones',
          },
          {
            name: 'Reporte agentes agencias',
            key: '/comercial/reportes/agentes-agencias',
            path: '/comercial/reportes/agentes-agencias',
          },
          {
            name: 'Reporte de ingresos mensual',
            key: '/comercial/reportes/ingresos-mensual',
            path: '/comercial/reportes/ingresos-mensual',
          },
        ],
      },
    ],
  },
  {
    name: 'Peticiones desarrollo',
    key: '/peticiones-desarrollo',
    children: [
      {
        name: 'Peticiones desarrollo',
        key: '/peticiones-desarrollo',
        path: '/peticiones-desarrollo',
      },
      {
        name: 'Mensaje Update',
        key: '/mensaje/update',
        path: '/mensaje/update',
      },
    ]
  },
];
const rootSubmenuKeys = MenuRoutes.map((ele) => ele.key);

export { MenuRoutes, rootSubmenuKeys };
