import React, { useEffect, useState } from 'react';
import MessageList from '../../../components/Message/MessageList';
import '../styles/styles.css';
import { Button, Col, Modal, Row } from 'antd';
import { ReloadOutlined, SyncOutlined, Icon, EditOutlined } from '@ant-design/icons';
import CreateNewMessage from '../../../components/Message/CreateNewMessage';
import { reserva } from '../../../services/reservacion';
import { messages } from '../../../services/messages';
import { connect } from 'react-redux';
import { some } from 'lodash';
import useSocket from '../../../hooks/useSocket';
import openNotification from '../../../components/Notificaciones';
import { loginTypes } from '../../../constants';
import io from 'socket.io-client';

const MessageHome = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState();
  const [reps, setReps] = useState();
  const [restart, setRestart] = useState();
  

  const isAdmin = some(props.roles, ['name', 'ADMIN']);
  //const isSuperAdmin = some(props.roles, ['name', 'SUPER ADMIN']);
  const isGteConcesion = some(props.roles, ['name', 'GERENTE CONCESION']);


  let { socket } = useSocket();
  //let socket = io();

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const updateMessageList = () => {
    setLoading(true);
    getMessages();
    setLoading(false);
  };
  const getMessages = async () => {
    const data = {
      id_user: props.user_id,
    };
    try {
      if (restart == true) {
        setRestart(false);
      }
      const response = await messages.getMessageByidUser(data);
      setData(response);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const countUnreadedMessages = async () => {
    const data = {
      id_user: props.user_id,
    };
    try {
      const response = await messages.getCountUnreadedMessage(data);
      props.setCountUnreadedMessage(response.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getAllRoles = async() => {
    try {
      const response = await messages.getAllRoles();
      setRoles(response.data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const getReps = async() => {

    const data = {
      id_main_roles: [1,2,8]
    }
    try {
      const response = await messages.getReps(data);
      setReps(response.data)
    } catch (error) {
      
    }
  }

  socket.on('correo_all', (info) => {
    setRestart(true);
  });

  socket.on('eliminar_correo_all', (info) => {
    setRestart(true);
  })
  
  useEffect(() => {
    getMessages();
    getAllRoles();
    countUnreadedMessages();
    if(isAdmin || isGteConcesion){
      getReps();
    }
  }, []);

  useEffect(() => {
    getMessages();
    countUnreadedMessages();
  }, [restart]);

  return (
    <div>
      <div>
        <span className="title-message">Mensajes</span>
      </div>
      <div>
        <Row gutter={[8, 8]} className="button-margin" justify="end">
          <Col>
            <Button
              loading={loading}
              icon={<SyncOutlined style={{ fontSize: '16px' }} />}
              onClick={() => updateMessageList()}
            >
              Actualizar
            </Button>
          </Col>
          {isAdmin ||
            isGteConcesion ? (
              <Col>
                <Button
                  icon={<EditOutlined style={{ fontSize: '16px' }} />}
                  onClick={() => openModal()}
                >
                  Nuevo Mensaje
                </Button>
                <Modal
                  title={<span className="title-modal">Enviar nuevo mensaje</span>}
                  visible={isOpen}
                  onCancel={closeModal}
                  footer={null}
                >
                  <CreateNewMessage setIsOpen={setIsOpen} setRestart={setRestart} roles={roles} reps={reps} />
                </Modal>
              </Col>
            ): (
              <></>
            )}
        </Row>
      </div>
      <MessageList data={data} loading={loadMoreData} setRestart={setRestart} />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setCountUnreadedMessage: (unreaded) =>
    dispatch({ type: loginTypes.COUNT_UNREADED_MESSAGE, unreaded }),
});

const mapStateToProps = (state) => {
  const { user } = state.login;
  return {
    user_id: user.user_id,
    roles: user.roles,
    rolesNuevos: user.rolesNuevos,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageHome);
