import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from './components/privateRoute';
import LoginPage from './pages/login';
import NotFoundPage from './components/NotFoundPage';
import { locationReps } from './services/locationReps';
import MessageHome from './pages/Message/MessageHome/Index';
import MessageContent from './pages/Message/MessageContent/Index';
import DailyAssigmentRole from './pages/DailyAssigmentRole/DailyAssigmentRole';
import channels from './pages/Channels/Channels';

// NOTA: revisar por que no puedo llamar la carpeta sin el nombre del archivo
const Reservas = lazy(() => import('./pages/reservas'));
const ProForma = lazy(() => import('./pages/ProForma'));
const ListProForma = lazy(() => import('./pages/ProForma/list'));
const EditProforma = lazy(() => import('./pages/ProForma/edit'));
const Checkout = lazy(() => import('./pages/reservas/checkout'));
const SuccessPage = lazy(() => import('./pages/reservas/sucessPage'));
const EditReservation = lazy(() => import('./pages/reservas/editar'));
const ReporteExcursion = lazy(() => import('./pages/reportes/excursion'));
const ReporteExcursionoptimizado = lazy(() => import('./pages/reportes/excursionoptimizado'));
const ReporteCancelacion = lazy(() => import('./pages/reportes/reporteCancelacion'));
const ReporteDescuentos = lazy(() => import('./pages/reportes/reporteDescuentos'));
const ReporteExcursionTest = lazy(() => import('./pages/reportes/excursionTest'));
const ReporteExcursionContabilidad = lazy(() => import('./pages/reportes/excursion_contabilidad'));
const ReporteExcursionCotizacion = lazy(() => import('./pages/reportes/excursion_cotizacion'));
const ComisionesReps = lazy(() => import('./pages/reportes/comission_reps'));
const ComisionesRepsDetails = lazy(() => import('./pages/reportes/comission_reps_details'));
const ReporteProduccion = lazy(() => import('./pages/reportes/reporte_produccion'));
const ReporteProduccionAnual = lazy(() => import('./pages/reportes/reporte_produccion_anual'));
const ReporteProduccionAnualProveedor = lazy(() =>
  import('./pages/reportes/reporte_produccion_anual_proveedor')
);

//const ReportesXproductos = lazy(() => import('./pages/reportes/reporte_reps_x_productos'));
const ReportesGerentes = lazy(() => import('./pages/reportes/reporte_gerentes'));
const VentasDolares = lazy(() => import('./pages/reportes/reporte_venta_dolares'));
const reporteCashCertificado = lazy(() => import('./pages/reportes/reporteCashCertificado'));
const LiquidacionDiaria = lazy(() => import('./pages/reportes/liquidacion_diaria'));
const CobroDiario = lazy(() => import('./pages/reportes/cobro_diario'));
const Reservaciones = lazy(() => import('./pages/reservas/list'));
const administrar_reserva = lazy(() => import('./pages/reservas/administrar_reserva'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const AdicionarFactura = lazy(() => import('./pages/reportes/adicionar_factura'));
const BuscarFacturas = lazy(() => import('./pages/reportes/buscar_facturas'));
const ReporteTransporte = lazy(() => import('./pages/reportes/reporte_transportacion'));
const ReporteTransportellegada = lazy(() =>
  import('./pages/reportes/reporte_transportacion_llegada')
);
const ComisionesPagadas = lazy(() => import('./pages/reportes/comisiones_pagadas'));
const ReporteTransportetraslados = lazy(() =>
  import('./pages/reportes/reporte_transportacion_traslados')
);
const ReporteTransporteCosteo = lazy(() => import('./pages/reportes/reporte_costeo_transporte'));
const reporteAgenciasComercial = lazy(() => import('./pages/reportes/reporte_agencias_comercial'));
const ReporteTransporteops = lazy(() => import('./pages/reportes/reporte_transportacion_ops'));
const AgruparCompartido = lazy(() => import('./pages/reportes/agrupar_compartido'));
const reporteAgruparCompartido = lazy(() => import('./pages/reportes/reporte_agrupar_compartido'));
const ReportePickups = lazy(() => import('./pages/reportes/reporte_pickups'));
const ReporteTransporteConfirmacion = lazy(() =>
  import('./pages/reportes/reporte_transportacion_confirmacion_servicio')
);
const ReporteCuponesConciliados = lazy(() =>
  import('./pages/reportes/reporte_cupones_conciliados')
);
const ReporteCuponesNoConciliados = lazy(() =>
  import('./pages/reportes/reporte_cupones_no_conciliados')
);

const CierrePorDia = lazy(() =>
  import('./pages/reportes/CierrePorDia')
);

const ProduccionXProveedor = lazy(() => import('./pages/reportes/produccion_x_proveedor'));
const excursionesxservicio = lazy(() => import('./pages/reportes/excursionesxservicio'));
const excursionesxserviciocurrency = lazy(() =>
  import('./pages/reportes/excursionesxserviciocurrency')
);
const firmas = lazy(() => import('./components/Forms/reservas/firmasretro'));

const crearPresupuesto = lazy(() => import('./pages/reportes/crearPresupuesto'));
const verPresupuesto = lazy(() => import('./pages/reportes/verPresupuesto'));
const repPresupuesto = lazy(() => import('./pages/reportes/repLocacion'));
const modificarPresupuesto = lazy(() => import('./pages/reportes/modificar_presupuesto'));
const reportePresupuestoCorte = lazy(() => import('./pages/reportes/reportePresupuestoCorte'));
const reportePresupuestoFechas = lazy(() => import('./pages/reportes/reportePresupuestoFecha'));
const reportePresupuestoGradoAlcance = lazy(() => import('./pages/reportes/reporteGradoAlcance'));
const reporteGradoAlcanceDias = lazy(() => import('./pages/reportes/reporteGradoAlcanceDias'));
const reporteRanqueoDias = lazy(() => import('./pages/reportes/reporteRanqueoDias'));
const reporteRanqueo = lazy(() => import('./pages/reportes/reporteRanqueo'));
const reportePresupuestoLocacionVenta = lazy(() =>
  import('./pages/reportes/reportePresupuestoLocacionVenta')
);
const salesReportByYear = lazy(() => import('./pages/reportes/SalesReportByYear'));
const ConvertionCampaigns = lazy(() => import('./pages/reportes/ConvertionCampaigns'));
const repPresupuestoAsistencia = lazy(() => import('./pages/reportes/repLocacionAsistencia'));
const repPresupuestoAsistenciaTodos = lazy(() =>
  import('./pages/reportes/repLocacionAsistenciaTodos')
);
const Presupuesto = lazy(() => import('./pages/reportes/presupuesto'));
const PresupuestoPeriodosCorte = lazy(() => import('./pages/reportes/periodosCortesQuincena'));
const reportePaisVenta = lazy(() => import('./pages/reportes/reportePaisVenta'));

const CalculoCostoExcursiones = lazy(() => import('./pages/reportes/calculo_costo_excursiones'));

const Incentives = lazy(() => import('./pages/reportes/incentives'));
const InceptivesReps = lazy(() => import('./pages/reportes/InceptivesReps'));

const ReporteOTLC = lazy(() => import('./pages/reportes/ReporteOTLC'));

const reporteComparativoAnual = lazy(() => import('./pages/reportes/reporteComparativoAnual'));

const reporteHampton = lazy(() => import('./pages/reportes/reporteHampton'));
const reporteEdicion = lazy(() => import('./pages/reportes/reporteEdicion'));
const CommissionsByRep = lazy(() => import('./pages/reportes/CommissionsByRep'));
const CommissionsBySubManager = lazy(() => import('./pages/reportes/CommissionsBySubManager'));

const reporteComparativoAnualxMes = lazy(() =>
  import('./pages/reportes/reporteComparativoAnualxMes')
);
const TarifarioExcursiones = lazy(() =>
  import('./pages/reportes/reporteTarifarioExcursiones')
);

const CommercialReport = lazy(() =>
  import('./pages/reportes/commercialReport')
);

const SolicitudesDeCancelacion = lazy(() => import('./pages/solicitudesDeCancelacion'));

const MsjUpdate = lazy(() => import('./pages/msjUpdate'));
const cargaMasiva = lazy(() => import('./pages/reportes/cargaMasiva'));

//comisiones por canal
const ComisionCanal = lazy(() => import('./pages/comisionPorCanal/ComisionCanal'));
const MostSelledProducts = lazy(() => import('./pages/reportes/MostSelledProducts'));

const OccupancyPercentage = lazy(() => import('./pages/occupancy-percentage/Index'));

//metas
// const UserGoalsIndex = lazy(() => import('./pages/metas/UserGoalsIndex'));
// const UserGoalsCreate = lazy(() => import('./pages/metas/UserGoalsCreate'));
// const UserGoalsUpdate = lazy(() => import('./pages/metas/UserGoalsUpdate'));
//metas sub gerente
// const UserGoalsIndexSubGerente = lazy(() => import('./pages/metasSubGerente/UserGoalsIndex'));
// const UserGoalsCreateSubGerente = lazy(() => import('./pages/metasSubGerente/UserGoalsCreate'));
// const UserGoalsUpdateSubGerente = lazy(() => import('./pages/metasSubGerente/UserGoalsUpdate'));
// const SupervisorGoalCreate = lazy(() => import('./pages/metasSupervisor/UserGoalsCreate'));
// const SupervisorGoal = lazy(() => import('./pages/metasSupervisor/UserGoalsIndex'));
// const SupervisorUpdate = lazy(() => import('./pages/metasSupervisor/UserGoalsUpdate'));
// const BothServicesReport = lazy(() => import('./pages/reportes/BothServicesReport'));
const CommissionsBySupervisor = lazy(() => import('./pages/reportes/CommissionsBySupervisor'));
// const DuplicarParametros = lazy(() => import('./pages/metasSupervisor/DuplicarParametros'));

//Todo: Reporte de costos proveedores solo para ruby
const CostosProveedores = lazy(() => import('./pages/reportes/costo_proveedores'));
//Newsletter
const NewsletterRefactor = lazy(() => import('./pages/newsletter/NewsletterRefactor'));

const Catalogs = lazy(() => import('./pages/catalogs/Index'));
const CatalogsCreate = lazy(() => import('./pages/catalogs/CreateContainer'));
const CatalogsUpdate = lazy(() => import('./pages/catalogs/UpdateContainer'));

const RepsLeaguesIndex = lazy(() => import('./pages/leagues/Index'));
const RepsLeaguesEditor = lazy(() => import('./pages/leagues/LeaguesEditor'));

const CustomersByRangeReport = lazy(() => import('./pages/reportes/CustomersByRangeReport'));

const ReportComparativeByDate  = lazy(() => import('./pages/reportes/reportComparativeByDate'));

const ReportRealPax  = lazy(() => import('./pages/reportes/reporte_pax_reales'));

const dashs = lazy(() => import('./pages/NewDashboard'))
const userConfig = lazy(() => import('./pages/NewUserConfig'))
const DataSheetGroups = lazy(() => import('./pages/grupos/dataSheet'));
const DataSheetGroupsCreate = lazy(() => import('./pages/grupos/dataSheet/Create'));
const DataSheetGroupsUpdate = lazy(() => import('./pages/grupos/dataSheet/Update'));

const DevelopmentRequest = lazy(() => import('./pages/peticiones'));
const DevelopmentRequestCreate = lazy(() => import('./pages/peticiones/Create'));
const DevelopmentRequestUpdate = lazy(() => import('./pages/peticiones/Update'));
const DevelopmentRequestApprobation = lazy(() => import('./pages/peticiones/Approbation'));

const DiscountsCommercialServices = lazy(() => import('./pages/reportes/DiscountsCommercialServices'));
const PaymentAccountReport = lazy(() => import('./pages/reportes/reporte_cobranza'));

const RetroactiveReservations = lazy(() => import('./pages/retroactive/index'))


const presupuesto_rep = lazy(() => import('./pages/BudgetRepLocations/index'));
const presupuesto_rep_report = lazy(() => import('./pages/BudgetRepLocations/report'));
const PresupuestoAsignacion = lazy(() => import('./pages/presupuesto/asignacion'));

const AgentesAgencias = lazy(() => import('./pages/reportes/AgentesAgencias'));
const IngresosMensual = lazy(() => import('./pages/reportes/IngresosMensual'));

const ReservationLed = lazy(() => import('./pages/ReservationsLed/ReservationsLed'));
const AssignmentLed = lazy(() => import('./pages/ReservationsLed/AssignmentLed'));

const IncentivesGlobal = lazy(() => import('./pages/reportes/IncentivesGlobal'));

function DisabledLocation() {
  const [msg, setMsg] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await locationReps.disabledLocation();
        setMsg(res.data);
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    };

    fetchData();
  }, []);
  return <div>{msg}</div>;
}

const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(24,144,255,10%)',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src="https://flevix.com/wp-content/uploads/2019/07/Alternate-Preloader.gif" alt="" />
    </div>
  );
};
const Router = ({ user }) => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route path="/login" component={LoginPage} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} auth={user} />
        <PrivateRoute exact path="/reservar" component={Reservas} auth={user} />
        <PrivateRoute exact path="/proforma" component={ProForma} auth={user} />
        <PrivateRoute exact path="/proforma-reservations" component={ListProForma} auth={user} />
        <PrivateRoute exact path="/proforma/:id" component={EditProforma} auth={user} />
        <PrivateRoute exact path="/reservas" component={Reservaciones} auth={user} />
        <PrivateRoute exact path="/reserva/:uuid" component={EditReservation} auth={user} />
        <PrivateRoute exact path="/checkout" component={Checkout} auth={user} />
        {/* No se como usaran esta parte pero les dejo un parametro opcional, si es requerido remover signo de interrogación */}
        <PrivateRoute path="/success/:uuid" auth={user} component={SuccessPage} />
        <PrivateRoute exact path="/reportes/excursiones" component={ReporteExcursion} auth={user} />
        <PrivateRoute exact path="/RetroactiveReservations" component={RetroactiveReservations} auth={user} />
        <PrivateRoute
          exact
          path="/reportes/excursiones/optimizado"
          component={ReporteExcursionoptimizado}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reportes/excursionesTwo"
          component={ReporteExcursionTest}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reportes/contabilidad"
          component={ReporteExcursionContabilidad}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reportes/cotizacion"
          component={ReporteExcursionCotizacion}
          auth={user}
        />
        <PrivateRoute exact path="/reportes/comisiones" component={ComisionesReps} auth={user} />
        <PrivateRoute
          exact
          path="/reporte/cancelacion"
          component={ReporteCancelacion}
          auth={user}
        />
        <PrivateRoute exact path="/reporte/descuentos" component={ReporteDescuentos} auth={user} />
        <PrivateRoute
          exact
          path="/reportes/comisiones/details"
          component={ComisionesRepsDetails}
          auth={user}
        />
        <PrivateRoute exact path="/reportes/produccion" component={ReporteProduccion} auth={user} />
        <PrivateRoute
          exact
          path="/reportes/produccionanual"
          component={ReporteProduccionAnual}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reportes/produccionanual/proveedor"
          component={ReporteProduccionAnualProveedor}
          auth={user}
        />
        {/* <PrivateRoute exact path="/reporte/productos" component={ReportesXproductos} auth={user} /> */}
        <PrivateRoute exact path="/reporte/gerentes" component={ReportesGerentes} auth={user} />
        <PrivateRoute exact path="/reporte/ventas_dolares" component={VentasDolares} auth={user} />
        <PrivateRoute
          exact
          path="/reporte/cashcertificado"
          component={reporteCashCertificado}
          auth={user}
        />
        <PrivateRoute exact path="/reporte/liquidacion" component={LiquidacionDiaria} auth={user} />
        <PrivateRoute exact path="/reporte/cobro" component={CobroDiario} auth={user} />
        <PrivateRoute exact path="/reporte/transporte" component={ReporteTransporte} auth={user} />
        <PrivateRoute exact path="/comisiones/pagadas" component={ComisionesPagadas} auth={user} />
        <PrivateRoute
          exact
          path="/reporte/transporte/costeo"
          component={ReporteTransporteCosteo}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/transporte/llegada"
          component={ReporteTransportellegada}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/transporte/ops"
          component={ReporteTransporteops}
          auth={user}
        />
        <PrivateRoute exact path="/agrupar-compartidos" component={AgruparCompartido} auth={user} />
        <PrivateRoute
          exact
          path="/reporte-agrupar-compartidos"
          component={reporteAgruparCompartido}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/transporte/confirmacion-servicio"
          component={ReporteTransporteConfirmacion}
          auth={user}
        />
        <PrivateRoute exact path="/reporte/pickups" component={ReportePickups} auth={user} />
        <PrivateRoute
          exact
          path="/adicionar/factura/:id?"
          component={AdicionarFactura}
          auth={user}
        />
        <PrivateRoute exact path="/buscar/facturas" component={BuscarFacturas} auth={user} />
        <PrivateRoute
          exact
          path="/cupones/conciliados"
          component={ReporteCuponesConciliados}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/calculo/costo/excursiones"
          component={CalculoCostoExcursiones}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/produccion/proveedor"
          component={ProduccionXProveedor}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reservas/administrar"
          component={administrar_reserva}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/cupones/no/conciliados"
          component={ReporteCuponesNoConciliados}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/cierre/dia"
          component={CierrePorDia}
          auth={user}
        />
        <PrivateRoute exact path="/firmas" component={firmas} auth={user} />
        <PrivateRoute exact path="/crear/presupuesto" component={crearPresupuesto} auth={user} />
        <PrivateRoute exact path="/presupuesto/ver" component={verPresupuesto} auth={user} />
        <PrivateRoute exact path="/asignar/locacion/rep" component={repPresupuesto} auth={user} />
        <PrivateRoute
          exact
          path="/modificar/locacion/rep"
          component={modificarPresupuesto}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/asistencia/locacion/rep"
          component={repPresupuestoAsistencia}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/asistencia/locacion/rep/todos"
          component={repPresupuestoAsistenciaTodos}
          auth={user}
        />
        <PrivateRoute exact path="/presupuesto" component={Presupuesto} auth={user} />
        <PrivateRoute
          exact
          path="/presupuesto/periodos/corte"
          component={PresupuestoPeriodosCorte}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/presupuesto/corte"
          component={reportePresupuestoCorte}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/presupuesto/fechas"
          component={reportePresupuestoFechas}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/presupuesto/grado-alcance"
          component={reportePresupuestoGradoAlcance}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/presupuesto/grado-alcance/dias"
          component={reporteGradoAlcanceDias}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/presupuesto/ranqueo/dias"
          component={reporteRanqueoDias}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/presupuesto/ranqueo"
          component={reporteRanqueo}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/presupuesto/locacion/ventas"
          component={reportePresupuestoLocacionVenta}
          auth={user}
        />
        <PrivateRoute exact path="/reporte/pais/venta" component={reportePaisVenta} auth={user} />
        <PrivateRoute exact path="/inventivos/proveedores" component={Incentives} auth={user} />
        //Todo: Costo Proveedores
        <PrivateRoute exact path="/costo/proveedores" component={CostosProveedores} auth={user} />
        <PrivateRoute
          exact
          path="/costo/excursionesxservicio"
          component={excursionesxservicio}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/costo/excursionesxservicio/currency"
          component={excursionesxserviciocurrency}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/transporte/traslados"
          component={ReporteTransportetraslados}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/comparativo/anual"
          component={reporteComparativoAnual}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/comparativo/mensual"
          component={reporteComparativoAnualxMes}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/ventas-produccion-anual"
          component={salesReportByYear}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/agencias/comercial"
          component={reporteAgenciasComercial}
          auth={user}
        />
        <PrivateRoute
          exact
          path="/reporte/conversion-camp"
          component={ConvertionCampaigns}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/reporte/incentivos-reps"
          component={InceptivesReps}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/reporte/otlc"
          component={ReporteOTLC}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/commissions-by-channel"
          component={ComisionCanal}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/report/most-selled-last-week"
          component={MostSelledProducts}
          auth={user}
        ></PrivateRoute>
        {/* <PrivateRoute
          exact
          path="/metas-comercial"
          component={UserGoalsIndex}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/metas-comercial-create"
          component={UserGoalsCreate}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/metas-comercial-update/:id"
          component={UserGoalsUpdate}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/metas-comercial-sub-gerente"
          component={UserGoalsIndexSubGerente}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/metas-comercial-create-sub-gerente"
          component={UserGoalsCreateSubGerente}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/metas-comercial-update-sub-gerente/:id"
          component={UserGoalsUpdateSubGerente}
          auth={user}
        ></PrivateRoute> */}
        {/* <PrivateRoute
          exact
          path="/comisiones-supervisor-create"
          component={SupervisorGoalCreate}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/comisiones-supervisor-update/:id"
          component={SupervisorUpdate}
          auth={user}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/comisiones-supervisor"
          component={SupervisorGoal}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/duplicar-parametros"
          component={DuplicarParametros}
          auth={user}
        ></PrivateRoute> */}

        <PrivateRoute
          exact
          path="/reporte-comisones-supervisor"
          component={CommissionsBySupervisor}
          auth={user}
        ></PrivateRoute>

        {/* // NewsLetter */}
        <PrivateRoute
          exact
          path="/newsletter"
          component={NewsletterRefactor}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/catalogs"
          component={Catalogs}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/catalogs/create"
          component={CatalogsCreate}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/catalogs/update/:id"
          component={CatalogsUpdate}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/reps-leagues"
          component={RepsLeaguesIndex}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/reps-leagues-editor"
          component={RepsLeaguesEditor}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/occupancy-percentage"
          component={OccupancyPercentage}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/report/customers-by-range"
          component={CustomersByRangeReport}
          auth={user}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/report/comparative-by-dates"
          component={ReportComparativeByDate}
          auth={user}
        >
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/report/real-pax"
          component={ReportRealPax}
          auth={user}
        >
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/grupos/ficha-tecnica"
          component={DataSheetGroups}
          auth={user}
        >
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/grupos/ficha-tecnica/crear"
          component={DataSheetGroupsCreate}
          auth={user}
        >
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/grupos/ficha-tecnica/:id/editar"
          component={DataSheetGroupsUpdate}
          auth={user}
        >
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/peticiones-desarrollo"
          component={DevelopmentRequest}
          auth={user}
        >
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/peticiones-desarrollo/create"
          component={DevelopmentRequestCreate}
          auth={user}
        >
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/peticiones-desarrollo/:id/edit"
          component={DevelopmentRequestUpdate}
          auth={user}
        >
        </PrivateRoute>
        <Route
          exact
          path="/peticiones-desarrollo/:id/aprobacion"
          component={DevelopmentRequestApprobation}
        />
        <PrivateRoute
          exact
          path="/comercial/reportes/descuentos-servicios-comercial"
          component={DiscountsCommercialServices}
          auth={user}
        >
        </PrivateRoute>
        <PrivateRoute
            exact
            path="/comercial/reportes/reporte_cobranza"
            component={PaymentAccountReport}
            auth={user}
        >
        </PrivateRoute>
        <PrivateRoute
            exact
            path="/comercial/reportes/agentes-agencias"
            component={AgentesAgencias}
            auth={user}
        />
        <PrivateRoute
            exact
            path="/comercial/reportes/ingresos-mensual"
            component={IngresosMensual}
            auth={user}
        />
        {/* <PrivateRoute
          exact
          path="/report/both-services-list"
          component={BothServicesReport}
          auth={user}
        ></PrivateRoute> */}
        <PrivateRoute exact path="/reporte/hampton" component={reporteHampton} auth={user} />
        <PrivateRoute exact path="/reporte/edicion" component={reporteEdicion} auth={user} />
        <PrivateRoute exact path='/report/commissions-by-channel' component={CommissionsByRep} auth={user} />
        <PrivateRoute exact path='/report/commissions-by-sub-manager' component={CommissionsBySubManager} auth={user} />
        <PrivateRoute exact path="/solicitudes-de-cancelacion" component={SolicitudesDeCancelacion} auth={user} />
        <PrivateRoute exact path="/mensaje/update" component={MsjUpdate} auth={user} />
        {/* TODOS RUTAS DE CARGA MASIVA */}
        <PrivateRoute exact path="/carga/masiva/productos" component={cargaMasiva} auth={user} />
        <PrivateRoute exact path="/comercial/reportes/tarifario-excursiones" component={TarifarioExcursiones} auth={user} />
        <PrivateRoute exact path="/comercial/reportes/CommercialReport" component={CommercialReport} auth={user} />
        <Route exact path="/new-dashboard" component={dashs} auth={user} />
        <Route exact path="/new-user" component={userConfig} auth={user} />
        <PrivateRoute exact path="/presupuesto/asignacion" component={presupuesto_rep} auth={user} />
        <PrivateRoute exact path="/presupuesto/reporte" component={presupuesto_rep_report} auth={user} />
        <PrivateRoute
          exact
          path="/presupuesto/periodos/"
          component={PresupuestoAsignacion}
          auth={user}
        />
        {/* <PrivateRoute exact path="/reservas/leds/reporte" component={ReservationLed} auth={user} /> */}
        <PrivateRoute exact path="/reservas/leds/asignacion" component={AssignmentLed} auth={user} />
        <PrivateRoute exact path="/mensaje" component={MessageHome} auth={user} />
        <PrivateRoute exact path="/mensaje/:id" component={MessageContent} auth={user} />
        <PrivateRoute exact path="/asignacion/rol-diario-supervision" component={DailyAssigmentRole} auth={user} />
        <PrivateRoute exact path="/comisiones/canales" component={channels} auth={user} />
        <PrivateRoute exact path="/incentives/report" component={IncentivesGlobal} auth={user} />
        <Route path="/disabled_location" component={DisabledLocation}  />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};
export default Router;
